import React from "react"
import { Container } from "react-bootstrap"
import LoginForm from "components/pages/LoginForm"
import { Helmet } from "react-helmet"
const Login = () => {
	return (
		<>
			<Helmet>
				<title> MercurySols Technologies | Login </title>
			</Helmet>
			<Container>
				<LoginForm />
			</Container>
		</>
	)
}

export default Login
