// extracted by mini-css-extract-plugin
export var antFormItemLabel = "styles-module--ant-form-item-label--FHT72";
export var antInput = "styles-module--ant-input--iX9om";
export var antInputFocused = "styles-module--ant-input-focused--uhf2x";
export var lFHeading = "styles-module--lFHeading--OwWJI";
export var lFHeadingDiv = "styles-module--lFHeadingDiv--ZLlyv";
export var lFMain = "styles-module--lFMain--+C9wU";
export var lFSub = "styles-module--lFSub--IHrMD";
export var lFbtn = "styles-module--lFbtn--1XhGY";
export var lFbtnDiv = "styles-module--lFbtnDiv--vi1AX";
export var lFformItem = "styles-module--lFformItem--3V65s";
export var lFinput = "styles-module--lFinput--W9j3y";
export var primary = "\"abc\"";
export var secondary = "\"dec\"";